exports.components = {
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-company-news-tsx": () => import("./../../../src/templates/companyNews.tsx" /* webpackChunkName: "component---src-templates-company-news-tsx" */),
  "component---src-templates-competitor-tsx": () => import("./../../../src/templates/competitor.tsx" /* webpackChunkName: "component---src-templates-competitor-tsx" */),
  "component---src-templates-customer-story-tsx": () => import("./../../../src/templates/customerStory.tsx" /* webpackChunkName: "component---src-templates-customer-story-tsx" */),
  "component---src-templates-download-tsx": () => import("./../../../src/templates/download.tsx" /* webpackChunkName: "component---src-templates-download-tsx" */),
  "component---src-templates-error-tsx": () => import("./../../../src/templates/error.tsx" /* webpackChunkName: "component---src-templates-error-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-marketplace-product-tsx": () => import("./../../../src/templates/marketplaceProduct.tsx" /* webpackChunkName: "component---src-templates-marketplace-product-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-partner-page-tsx": () => import("./../../../src/templates/partnerPage.tsx" /* webpackChunkName: "component---src-templates-partner-page-tsx" */),
  "component---src-templates-press-release-tsx": () => import("./../../../src/templates/pressRelease.tsx" /* webpackChunkName: "component---src-templates-press-release-tsx" */),
  "component---src-templates-product-update-tsx": () => import("./../../../src/templates/productUpdate.tsx" /* webpackChunkName: "component---src-templates-product-update-tsx" */),
  "component---src-templates-quiz-tsx": () => import("./../../../src/templates/quiz.tsx" /* webpackChunkName: "component---src-templates-quiz-tsx" */),
  "component---src-templates-scoop-state-tsx": () => import("./../../../src/templates/scoopState.tsx" /* webpackChunkName: "component---src-templates-scoop-state-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */)
}

